import React, {useState, useEffect} from 'react'
import { Button, Popover, Grid, makeStyles, CardMedia, Card, Box, CardActions , CardContent, Typography } from '@material-ui/core';
import { Autorenew } from '@material-ui/icons';
import AddIcon from '@material-ui/icons/Add';
import ConfiguratorCard from './ConfiguratorCard'
import { getConfiguratorResult } from './ConfiguratorLogic' 
import { useRecoilState, useRecoilValue } from "recoil"
import { configuratorQuestionState, configuratorResultState, leadRecordState } from "../../store"
import ArrowLink from "../shared/ArrowLink"
import EmailModal from "./EmailModal"
import SuccessDialog from "../shared/SuccessDialog"
import ImageSlider from '../shared/ImageSlider';
import Api from '../../api'

const useStyles = makeStyles((theme) => ({
    fullHeight: {
        flexGrow: 1,
        overflowX: "hidden"
    },
    leftBox: {
        minHeight: "100%",
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    details: {
      display: 'flex',
      flexDirection: 'column',
    },
    priceBox: {
        marginTop: 'auto',
        paddingTop: theme.spacing(2),
    },
    price: {
        marginTop: "auto"
    },
    title: {
        color: theme.palette.primary.main,
        marginBottom: theme.spacing(3)
    },
    content: {
        height: "100%",
        flex: '1 0 auto',
        display: "flex",
        flexDirection: "column"
    },
    feature: {
        paddingTop: theme.spacing(1),
        display: "flex",
    },
    icon: {
        marginRight: theme.spacing(1),
    },
    cover: {
        wrapper	: {
            height: "100%",
            minHeight: "200px",
            margin: "0 auto",
            width: "300",
            borderBottomLeftRadius: "15px",
        }
    },
    mediaBox: {
        background: theme.palette.action.hover
    },
    actionGrid: {
        marginTop: "auto",
        borderTop: `solid 1px ${theme.palette.action.hover}`,
    },
    action: {
        display: 'flex',
        padding: theme.spacing(3),
        width: "100%",
        marginTop: "auto"
    },
    pictureGrid: {
        width: "100%",
        overflowX: "hidden"
    }
  }));


const Results = () =>  {
    const [ questionState ] = useRecoilState(configuratorQuestionState);
    const resultState = useRecoilValue(configuratorResultState);
    const [leadRecord] = useRecoilState(leadRecordState);
    const [dialogIsOpen, setDialogOpen] = useState(false);
    // useEffect(() => {
    //     console.log("questionState", questionState)

    //     let result = getConfiguratorResult(questionState)

    //     if (result) {
    //         console.log("RESULT", result)
    //         setResultState({...result})
    //     }
    // }, [questionState])


    const classes = useStyles()

    const handleWantsToEnquire = async () => {
        console.log("leadRecord", leadRecord, resultState)
        await Api.updateLead({id: leadRecord.id, wantsToEnquire: true, configuratorResult: resultState.name})
        setDialogOpen(true)
    }
    const handleWantsFinancing = async () => {
        Api.updateLead({id: leadRecord.id, wantsFinancing: true, configuratorResult: resultState.name})
        setDialogOpen(true)
    }
    return (
        <ConfiguratorCard noPadding>
                    {/* <SuccessDialog 
                title="Thanks!"
                content="You will hear from us!" 
                acceptText="Back"
                isOpen={dialogIsOpen} 
                handleClose={() => setDialogOpen(false)}
                ></SuccessDialog> */}
                <EmailModal
                    isOpen={dialogIsOpen} 
                    handleClose={() => setDialogOpen(false)}
                >
                </EmailModal>
                <Grid container className={classes.fullHeight}>
                    <Grid item md={6} className={classes.leftBox}>
                        <CardContent className={classes.content}>
                            <Typography variant="h5" className={classes.title}>
                                We recommend:
                            </Typography>
                            <Typography variant="h5">
                                {resultState.name}
                            </Typography>
                            { resultState.id === "UNKNOWN" ? ( 
                                <Typography className={classes.feature}>
                                    We currently do not offer any matching product for your business, but we might be able to find a custom solution that fulfills your needs.
                                </Typography>
                            ) : 
                                <>
                                    { resultState.structureDimensions && (
                                        <Typography className={classes.feature}>
                                            <AddIcon color="secondary" className={classes.icon}/>
                                                {resultState.structureDimensions} sqm
                                        </Typography>
                                    )}
                 
                                        {resultState.features && resultState.features.map( feature => 
                                            <Typography className={classes.feature}>
                                                <AddIcon color="secondary" className={classes.icon}/>
                                                    {feature}
                                            </Typography>
                                        )}
                                </>
                            }
                                       <br></br>
                                       <b> Please get in touch to get custom pricing analysis</b>
                                       
                        </CardContent>
                    </Grid>
                    <Grid item md={6} className={classes.pictureGrid}>
                    <ImageSlider images={[...resultState.pictures, resultState.layout]}></ImageSlider>
                            {/* <CardMedia
                                className={classes.cover}
                                image={resultState.picture}
                                title="Picture of the product"
                            /> */}
                    </Grid>
                </Grid>
                <Grid container className={classes.actionGrid}>
                    <Grid item md={8}>
                        <CardActions className={classes.action}>
                                <Button onClick={handleWantsToEnquire} variant="contained" color="primary">
                                    Enquire Us!
                                </Button>
                                <Button onClick={handleWantsFinancing} variant="outlined" color="primary">
                                    Finance it!
                                </Button>
                        </CardActions>
                    </Grid>
                    <Grid item md={4} className={classes.action}>
                        <a style={{textDecoration: "none"}} target="_blank" href="https://www.inspirafarms.com/products/"><ArrowLink text="Learn More" onClick={() => console.log("KLICKKKK")}></ArrowLink></a>
                    </Grid>
                </Grid>
        </ConfiguratorCard>
    )
}

export default Results
