import axios from 'axios';

const qs = require('querystring');
const url = "https://api.inspirafarms.com/service/configurator";

const ct = require('countries-and-timezones');
const { detect } = require('detect-browser');
const browser = detect();


const urlEncodeConfig = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  }

  class Lead {
      constructor({name, company, typeOfProduce, typeOfProduct, amountOfThroughput}) {
      
        if (name) this.name= name;
        if (company) this.company = company;
        if (typeOfProduct) this.product = typeOfProduct;
        if (typeOfProduce) this.produce = typeOfProduce;
        if (amountOfThroughput) this.throughput = amountOfThroughput;

      }
  }

  class UpdateLeadRequest {
    constructor({id, wantsToEnquire,phoneNumber, wantsFinancing, configuratorResult, name, company, email, typeOfProduce, typeOfProduct, amountOfThroughput}) {
      this.id = id;
      // never use it like that, interesting https://stackoverflow.com/questions/7820683/convert-boolean-result-into-number-integer
      if (wantsToEnquire) this.wantsToEnquire = + wantsToEnquire;
      if (wantsFinancing) this.wantsFinancing = + wantsFinancing;
      if (configuratorResult) this.configuratorResult = configuratorResult;
      if (name) this.name= name;
      if (company) this.company = company;
      if (email) this.email = email;
      if (typeOfProduct) this.product = typeOfProduct;
      if (typeOfProduce) this.produce = typeOfProduce;
      if (amountOfThroughput) this.throughput = amountOfThroughput;
      if (phoneNumber) this.phoneNumber = phoneNumber;
    }
}

const api = {
    createLead: (state) => {
        const lead = new Lead(state)
        
        //const data_validation = validateLeadRequest(lead);
          var currentdate = new Date();
          var datetime = currentdate.getDate() + "/" + getMonth()
          + "/" + currentdate.getFullYear();
          var timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
          const country = ct.getCountryForTimezone(timezone);
        function getMonth() {
            var currentdate = new Date();
            var month = (parseInt(currentdate.getMonth()) + 1);
            return month < 10 ? '0' + month : '' + month; // ('' + month) for string result
        }
        if (browser) {
          lead.browser = browser.name;
          lead.os = browser.os;
          //lead.browserversion = browser.version;
        } else{
          lead.browser = 'N/A';
          lead.os = 'N/A';
        }
        lead.date = datetime;
        lead.country = country.name? country.name : timezone;
        
        return axios.post(`${url}/lead-generation`, qs.stringify(lead), urlEncodeConfig)
        .then(res => {
            // console.log(res.data);
            return res.data[0].id
        })
        .catch(function (error) {
            // handle error
            console.log(error);
            return false
        })
    },
    updateLead: (state) => {
        const updateLeadRequest = new UpdateLeadRequest(state)
       // var currentdate = new Date();

    //     var datetime = currentdate.getDate() + "/" + (parseInt(currentdate.getMonth()) + 1)
    //         + "/" + currentdate.getFullYear();
    //    // var timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    // //     var datetime = "Date: " + currentdate.getDate() + "/" + (parseInt(currentdate.getMonth()) + 1)
    // //     + "/" + currentdate.getFullYear() + " Time: "
    // //     + currentdate.getHours() + ":"
    // //     + currentdate.getMinutes() + ":" + currentdate.getSeconds();
    // // var timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    //     updateLeadRequest.date = datetime;
    //     updateLeadRequest.timezone = timezone;
            //console.log(">>>>>>>>>>>",updateLeadRequest)
      return axios.patch(`${url}/lead-generation`, qs.stringify(updateLeadRequest), urlEncodeConfig)
        .then(res => {
            // console.log(res.data);
            return res.data[0].id
        })
        .catch(function (error) {
            // handle error
            console.log(error);
            return false
        })
    }
}

export default api