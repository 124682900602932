// LAYOUTS

import CS25_layout from '../../assets/layouts/CS25_FLEXI_outdoor_(Bloom_Growers).jpeg';
import CS50_layout from '../../assets/layouts/CS50_FLEXI_outdoor.jpeg'
import CS100_layout from '../../assets/layouts/CS100_FLEXI_outdoor.jpeg'
import CS100_depression_layout from '../../assets/layouts/CS100_FLEXI_outdoor_30_Depression_Boot_(Kakuzi_Avo_Exp).jpeg'
import FP150_layout from '../../assets/layouts/FP150_FLEXI_outdoor.jpeg'
import FP200_layout from '../../assets/layouts/FP200_FLEXI_outdoor.jpeg'
import FP200_layout_smaller from '../../assets/layouts/FP200_FLEXI_outdoor_(Moonlight smaller).jpeg'
import FP210_layout from '../../assets/layouts/FP210_FLEXI_outdoor_(Ifria Benin).jpeg'
import FP250_layout from '../../assets/layouts/FP210_FLEXI_outdoor_(Ifria Benin).jpeg'
import FP300_layout from '../../assets/layouts/FP300_FLEXI_outdoor_(Hornbill,Volta).jpeg'
import FP500_layout_moonlight_bigger from '../../assets/layouts/FP500_FLEXI_outdoor_(Moonlight_Bigger).jpeg'
import FP500_layout_skyfox from '../../assets/layouts/FP500_FLEXI_outdoor_(Skyfox).jpeg'
import FP500_layout_lauetta_x2 from '../../assets/layouts/FP500_FLEXI_outdoor_(2xLauetta).jpeg'
import FP875_layout from '../../assets/layouts/FP875_(E.Darkey).jpeg'
import FP900_layout from '../../assets/layouts/FP900_Flexi_Outdoor_(Tanfoods).jpeg'

import PictureColdRoom from '../../assets/images/products/kakuzi/coldroom_3.jpeg';
import PictureColdRoom2 from '../../assets/images/products/kakuzi/coldroom_2.jpeg';
import PictureColdRoom3 from '../../assets/images/products/kakuzi/coldroom_1.jpeg';

import PicturePackHouse from '../../assets/images/products/lauetta/packhouse_worker.jpg';
import PicturePackHouse2 from '../../assets/images/products/kakuzi/packhouse_1.jpeg';
import PicturePackHouse3 from '../../assets/images/products/kakuzi/coldroom_3.jpeg';

export const ConfiguratorEnums = {
    typeOfProduct: {
        coldroom: "coldroom",
        packhouse: "packhouse",
    },
    typeOfProduce: {
        flowers: "flowers",
        tropical: 'tropical',
        berries: 'berries',
        melon: 'melon',
        beans: 'beans',
        leafyGreens: 'leafyGreens',
        roots: 'roots',
        cruciferous: 'cruciferous',
        other: 'other'
    },
    amountOfThroughput: [
      {
        value: 5,
        label: "1-5t"
      },
      {
        value: 40,
        label: "5-15t"
      },
      {
        value: 70,
        label: "15-30t"
      },
      {
        value: 100,
        label: "> 30t"
      }
    ]
}

export class Product {
  constructor({
    modelId,
    name, 
    features,
    priceStartingAt = '', 
    model, 
    structureType, 
    structureDimensions, 
    CsDoorsType = "sliding",
    CsDoorHeight = "230 cm",
    totalKw,
    FAP = false,
    IOT = true,
    price,
    pictures = [PictureColdRoom],
    layout = FP150_layout,
  }) {
    Object.assign(this, { modelId, name, features, priceStartingAt, model, structureType, structureDimensions, CsDoorsType, totalKw, FAP, IOT, price, pictures, layout });
  }
}

const basicColdroomFeatures = ['22°C temp drop in 12 hours', 'Modular built with structural inter-locking with high density closed cells PUR panels', 'Includes humidity and airflow control and can be equipped with blast chillers for forced air pre-cooling.', 'Remote Monitoring', 'Food-safety compliant', ' High density closed cells PUR panels with efficient cooling machinery and electronics allows 25% of energy savings.', 'Turn-key delivery, ready to be installed. It only requires a concrete flooring and a roofing structure.']
const basicPackhouseFeatures = ['22°C temp drop in 12 hours', 'Modular structure in 5 x 5 meters modules, built with laser cut steel and hot-dip galvanized after welding, with walls and ceilings made of high density closed cells PUR panels.', 'Includes humidity and airflow control and can be equipped with blast chillers for forced air pre-cooling.', 'Remote Monitoring', 'Food-safety compliant', ' High density closed cells PUR panels with efficient cooling machinery and electronics allows 25% of energy savings.', 'Turn-key delivery, ready to be installed. It only requires a concrete flooring.']

const Products = [
  new Product({
    modelId: "UNKNOWN",
    name: "Custom Packhouse",
    features: [...basicPackhouseFeatures],
    price: null
  }), 
  new Product({
    modelId: "CS25",
    name: "Cold Storage CS25",
    features: ["up to 12 pallets", ...basicColdroomFeatures],
    structureDimensions: 25,
    totalKw: 15,
    layout: CS25_layout,
    price: 20165,
    pictures: [PictureColdRoom, PictureColdRoom2, PictureColdRoom3]
  }), 
  new Product({
    modelId: "CS50",
    name: "Cold Storage CS50",
    features: ["up to 20 pallets", ...basicColdroomFeatures],
    structureDimensions: 50,
    totalKw: 30,
    layout: CS50_layout,
    price: 40000,
    pictures: [PictureColdRoom, PictureColdRoom2, PictureColdRoom3]
  }), 
  new Product({
    modelId: "CS100",
    name: "Coldstorage CS100",
    features: ["up to 40 pallets", ...basicColdroomFeatures],
    structureDimensions: 100,
    totalKw: 60,
    layout: CS100_layout,
    price: 48560,
    pictures: [PictureColdRoom, PictureColdRoom2, PictureColdRoom3]
  }), 
  new Product({
    modelId: "CS500",
    name: "Coldstorage CS500",
    features: ["up to 80 pallets", ...basicColdroomFeatures],
    structureDimensions: 500,
    pictures: [PictureColdRoom, PictureColdRoom2, PictureColdRoom3]
  }), 
  new Product({
    modelId: "FP100",
    name: "Packhouse FP100",
    features: ["up to 40 pallets", ...basicPackhouseFeatures],
    structureDimensions: 100,
    pictures: [PicturePackHouse, PicturePackHouse2, PicturePackHouse3]
  }), 
  new Product({
    modelId: "FP150",
    name: "Packhouse FP150",
    features: [...basicPackhouseFeatures],
    structureDimensions: 150,
    layout: FP150_layout,
    price: 75000,
    pictures: [PicturePackHouse, PicturePackHouse2, PicturePackHouse3]
  }), 
  new Product({
    modelId: "FP200",
    name: "Packhouse FP200",
    features: [...basicPackhouseFeatures],
    structureDimensions: 200,
    layout: FP200_layout,
    price: 95000,
    pictures: [PicturePackHouse, PicturePackHouse2, PicturePackHouse3]
  }),
  new Product({
    modelId: "FP210",
    name: "Packhouse FP210",
    structureDimensions: 210,
    features: [...basicPackhouseFeatures],
    layout: FP210_layout,
    price: 160000,
    pictures: [PicturePackHouse, PicturePackHouse2, PicturePackHouse3]
  }), 
  new Product({
    modelId: "FP250",
    name: "Packhouse FP250",
    structureDimensions: 250,
    features: [...basicPackhouseFeatures],
    layout: FP250_layout,
    price: 126000,
    pictures: [PicturePackHouse, PicturePackHouse2, PicturePackHouse3]
  }), 
  new Product({
    modelId: "FP300",
    name: "Packhouse FP300",
    structureDimensions: 300,
    features: [...basicPackhouseFeatures],
    price: 310000,
    pictures: [PicturePackHouse, PicturePackHouse2, PicturePackHouse3]
  }), 
  new Product({
    modelId: "FP300_volta",
    name: "Packhouse FP300",
    structureDimensions: 300,
    features: [...basicPackhouseFeatures],
    layout: FP300_layout,
    price: 310000,
    pictures: [PicturePackHouse, PicturePackHouse2, PicturePackHouse3]
  }), 
  new Product({
    modelId: "FP500",
    name: "Packhouse FP500",
    structureDimensions: 500,
    features: [...basicPackhouseFeatures],
    layout: FP500_layout_lauetta_x2,
    price: 252000,
    pictures: [PicturePackHouse, PicturePackHouse2, PicturePackHouse3]
  }), 
  new Product({
    modelId: "FP500_moonlight_bigger",
    name: "Packhouse FP500",
    structureDimensions: 500,
    features: [...basicPackhouseFeatures],
    layout: FP500_layout_moonlight_bigger,
    pictures: [PicturePackHouse, PicturePackHouse2, PicturePackHouse3]
  }),
  new Product({
    modelId: "FP500_skyfox",
    name: "Packhouse FP500",
    structureDimensions: 500,
    features: [...basicPackhouseFeatures],
    layout: FP500_layout_skyfox,
    pictures: [PicturePackHouse, PicturePackHouse2, PicturePackHouse3]
  }), 
  new Product({
    modelId: "FP875",
    name: "Packhouse FP875",
    structureDimensions: 875,
    features: [...basicPackhouseFeatures],
    layout: FP875_layout,
    price: 325000,
    pictures: [PicturePackHouse, PicturePackHouse2, PicturePackHouse3]
  }), 
  new Product({
    modelId: "FP900",
    name: "Packhouse FP875",
    structureDimensions: 900,
    features: [...basicPackhouseFeatures],
    layout: FP900_layout,
    pictures: [PicturePackHouse, PicturePackHouse2, PicturePackHouse3]
  }), 
]

const throughputSpreader = (amountOfThroughput, throughputEnumArray, case0, case1, case2, case3) => {
  switch(amountOfThroughput) {
    case throughputEnumArray[0].value:
      return case0;
    case throughputEnumArray[1].value:
      return case1;
    case throughputEnumArray[2].value:
      return case2;
    case throughputEnumArray[3].value:
      return case3;
  }
}


export const getConfiguratorResult = (state) => {
  console.log("STARTING get Config Result", state)
  let result;
    switch(state.typeOfProduct) {
        case ConfiguratorEnums.typeOfProduct.coldroom:
          console.log("COLDROOM")
          // COLDROOM

          switch(state.typeOfProduce) {
            case ConfiguratorEnums.typeOfProduce.tropical:
              return throughputSpreader(
                state.amountOfThroughput, 
                ConfiguratorEnums.amountOfThroughput,
                Products.find(product => product.modelId === "CS25"),
                Products.find(product => product.modelId === "CS50"),
                Products.find(product => product.modelId === "CS100"),
                Products.find(product => product.modelId === "CS100")
              )
            case ConfiguratorEnums.typeOfProduce.berries:
              return throughputSpreader(
                state.amountOfThroughput, 
                ConfiguratorEnums.amountOfThroughput,
                Products.find(product => product.modelId === "CS25"),
                Products.find(product => product.modelId === "CS50"),
                Products.find(product => product.modelId === "CS100"),
                Products.find(product => product.modelId === "FP500")
              )
            case ConfiguratorEnums.typeOfProduce.roots:
              return throughputSpreader(
                state.amountOfThroughput, 
                ConfiguratorEnums.amountOfThroughput,
                Products.find(product => product.modelId === "CS25"),
                Products.find(product => product.modelId === "CS50"),
                Products.find(product => product.modelId === "CS100"),
                Products.find(product => product.modelId === "FP300")
              )
            default:
              return throughputSpreader(
                state.amountOfThroughput, 
                ConfiguratorEnums.amountOfThroughput,
                Products.find(product => product.modelId === "CS25"),
                Products.find(product => product.modelId === "CS50"),
                Products.find(product => product.modelId === "CS100"),
                Products.find(product => product.modelId === "FP210")
              )
          }



        case ConfiguratorEnums.typeOfProduct.packhouse:
          // PACKHOUSE
          console.log("PACKHOUSE")

          switch(state.typeOfProduce) {
            case ConfiguratorEnums.typeOfProduce.flowers:
              return throughputSpreader(
                state.amountOfThroughput, 
                ConfiguratorEnums.amountOfThroughput,
                Products.find(product => product.modelId === "FP210"),
                Products.find(product => product.modelId === "FP300"),
                Products.find(product => product.modelId === "FP875"),
                Products.find(product => product.modelId === "FP875"),
                )
            case ConfiguratorEnums.typeOfProduce.tropical:
              return throughputSpreader(
                state.amountOfThroughput, 
                ConfiguratorEnums.amountOfThroughput,
                Products.find(product => product.modelId === "FP200"),
                Products.find(product => product.modelId === "FP500_moonlight_bigger"),
                Products.find(product => product.modelId === "FP900"),
                Products.find(product => product.modelId === "UNKNOWN"),
                )
            case ConfiguratorEnums.typeOfProduce.berries:
              return throughputSpreader(
                state.amountOfThroughput, 
                ConfiguratorEnums.amountOfThroughput,
                Products.find(product => product.modelId === "FP200"),
                Products.find(product => product.modelId === "FP250"),
                Products.find(product => product.modelId === "FP875"),
                Products.find(product => product.modelId === "UNKNOWN"),
                )
            case ConfiguratorEnums.typeOfProduce.melon:
              return throughputSpreader(
                state.amountOfThroughput, 
                ConfiguratorEnums.amountOfThroughput,
                Products.find(product => product.modelId === "FP200"),
                Products.find(product => product.modelId === "FP500_moonlight_bigger"),
                Products.find(product => product.modelId === "FP900"),
                Products.find(product => product.modelId === "UNKNOWN"),
                )
            case ConfiguratorEnums.typeOfProduce.beans:
              return throughputSpreader(
                state.amountOfThroughput, 
                ConfiguratorEnums.amountOfThroughput,
                Products.find(product => product.modelId === "FP200"),
                Products.find(product => product.modelId === "FP300"),
                Products.find(product => product.modelId === "FP875"),
                Products.find(product => product.modelId === "FP875"),
                )
              break;
            case ConfiguratorEnums.typeOfProduce.leafyGreens:
              return throughputSpreader(
                state.amountOfThroughput, 
                ConfiguratorEnums.amountOfThroughput,
                Products.find(product => product.modelId === "FP200"),
                Products.find(product => product.modelId === "FP500_moonlight_bigger"),
                Products.find(product => product.modelId === "FP875"),
                Products.find(product => product.modelId === "FP875"),
                )
              break;
            case ConfiguratorEnums.typeOfProduce.cruciferous:
              return throughputSpreader(
                state.amountOfThroughput, 
                ConfiguratorEnums.amountOfThroughput,
                Products.find(product => product.modelId === "FP150"),
                Products.find(product => product.modelId === "FP200"),
                Products.find(product => product.modelId === "FP500_moonlight_bigger"),
                Products.find(product => product.modelId === "FP875"),
                )
            case ConfiguratorEnums.typeOfProduce.roots:
                  return throughputSpreader(
                    state.amountOfThroughput, 
                    ConfiguratorEnums.amountOfThroughput,
                    Products.find(product => product.modelId === "FP200"),
                    Products.find(product => product.modelId === "FP500"),
                    Products.find(product => product.modelId === "FP875"),
                    Products.find(product => product.modelId === "UNKNOWN"),
                    )
            case ConfiguratorEnums.typeOfProduce.other:
              return throughputSpreader(
                state.amountOfThroughput, 
                ConfiguratorEnums.amountOfThroughput,
                Products.find(product => product.modelId === "FP150"),
                Products.find(product => product.modelId === "FP200"),
                Products.find(product => product.modelId === "FP500_skyfox"),
                Products.find(product => product.modelId === "UNKNOWN"),
                )
      }
      default:
        console.log("couldnt find a matchin product")
        return Products.find(product => product.modelId === "UNKNOWN")
    }
} 