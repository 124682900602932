import React from 'react';
import {
    makeStyles,
    Button, 
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    DialogContentText,
    Typography,
    Slide,
    withStyles,
     Grid
} from '@material-ui/core';
import PhoneField from '../shared/PhoneField'
import { useRecoilState, useRecoilValue } from "recoil"
import { configuratorQuestionState, configuratorResultState, leadRecordState } from "../../store"
import { SendOutlined } from '@material-ui/icons';
import Api from '../../api'

import TextField from '@material-ui/core/TextField';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CustomDialog = withStyles(theme => ({
    paper: {
      margin: 16,
      padding: theme.spacing(2)
    },
  }))(Dialog)

const useStyles = makeStyles((theme) => ({
    container: {
        paper: {
            margin: 16
        },
        minWidth: "100%",
    },
    dialogContentText: {
      marginBottom: theme.spacing(4),
    },
    cancelButton: {
        marginRight: 'auto'
    }
  }))

export default function EmailModal({isOpen, handleClose, title, content, acceptText, acceptFunction}) {
  const classes = useStyles()

  const [ questionState, setQuestionState ] = useRecoilState(configuratorQuestionState);
  const resultState = useRecoilValue(configuratorResultState);
  const [leadRecord] = useRecoilState(leadRecordState);

  const close = () => {
    handleClose()
  }
  const send = async () => {
    console.log("leadRecord", leadRecord, resultState)
    await Api.updateLead({id: leadRecord.id, email: questionState.email })
    alert("Thank you! Our team will be in touch shortly to take this discussion further ahead.");
    handleClose()
  }

    return (
      <CustomDialog
        maxWidth="sm"
        open={isOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        className={classes.container}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title"> Lets keep in touch! </DialogTitle>
        <DialogContent>
        <DialogContentText className={classes.dialogContentText}>
              We would like to take this discussion further, inorder to find an efficient solution in the shortest amount of time.
              Please leave your email address, so we can email you concerning your product.
          </DialogContentText>
          <Grid container justify="left" spacing={2}>
                        <Grid item xs={12} >  
                        <TextField onChange={event => setQuestionState({...questionState, email: event.target.value})}
            className={classes.input} type="email" required variant="filled" id="filled-basic" label="Email" />
                        </Grid>
                    </Grid>
        
        </DialogContent>
        <DialogActions>
          <Button onClick={close} color="primary" className={classes.cancelButton}>
           Call me instead.
          </Button>
          <Button onClick={send} variant="contained" color="primary" disabled={!questionState.email}>
            Email me!
          </Button>
   
        </DialogActions>
      </CustomDialog>
    );
  }
  