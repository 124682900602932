import React, {useState} from 'react';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
import './PhoneFieldStyles.sass'
import { TextField, useTheme, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    input: {
        borderColor: `${theme.palette.primary.main}`,
        ':focus': {
            borderColor: `${theme.palette.primary.main}`,
            boxShadow: `0 0 0 1px ${theme.palette.primary.main}`
        },
        '& :focus': {
            borderColor: `${theme.palette.primary.main}`,
            boxShadow: `0 0 0 1px ${theme.palette.primary.main}`
        }
    }
  }))
 
function PhoneField(props) {
  const { value, defaultCountry, onChange, handleOnChange } = props;
  const theme = useTheme()
  const classes = useStyles()
  const style = {
      borderColor: `${theme.palette.primary.main}`,
    ':focus': {
        borderColor: `${theme.palette.primary.main}`,
        boxShadow: `0 0 0 1px ${theme.palette.primary.main}`
    },
    '& :focus': {
        borderColor: `${theme.palette.primary.main}`,
        boxShadow: `0 0 0 1px ${theme.palette.primary.main}`
    }
  };

  return (
      <PhoneInput
        // value={value}
        // inputStyle={style}
        // inputClass={classes.input}
        defaultCountry={'ke'}
        onChange={handleOnChange}
        preferredCountries={['ke', 'rw', 'et', 'tz', 'zw', 'zm', 'ug', 'ng', 'na', 'mz', 'gh', 'gm', 'ga', 'za']}
        // inputClass={classes.field}
        // dropdownClass={classes.countryList}
        component={TextField}
        // inputExtraProps={{
        //   margin: 'normal',
        //   autoComplete: 'phone',
        //   name: 'custom-username'
        // }}
      />
  );
}
 
export default PhoneField;