import React from 'react';
import PropTypes from 'prop-types';
import { Link } from "gatsby-theme-material-ui";
import { makeStyles } from '@material-ui/core/styles';
import LogoSVG from '../../assets/logo.svg';
import Arrow from '../../assets/icons/arrow-right.svg';

const useStyles = makeStyles((theme) => ({
  link: { 
      display: "flex",
      flexDirection: "row",
      '&:hover': {
          textDecoration: "none",
        '& $arrow': {
            transform: 'translateX(3px)'
          },
        '& $arrowLeft': {
            transform: 'rotate(180deg) translateX(3px)'
          },
     },
   },
  arrow: {
    transition: "all ease-in-out 0.5",
    marginLeft: -10,
    height: '30px',
    width: '50px',
    '& g': {
      '& path': {
        fill: "none"
      }
    }
  },
  arrowLeft: {
    transition: "all ease-in-out 0.5",
    transform: "rotate(180deg)",
    marginRight: -10,
    height: '30px',
    width: '50px'
  }
}))


const ArrowLink = ({ left, right, text, onClick, link }) => {
  const classes = useStyles()

  return (
    <Link
      component="button"
      variant="body1"
      className={classes.link}
      to={link && link}
      onClick={() => {
        onClick()
      }}
    >
     { left && <Arrow className={classes.arrowLeft}></Arrow>} {text} { !left && <Arrow className={classes.arrow}></Arrow>}
    </Link>
  );
};

// NavLogo.propTypes = {
// };

// NavLogo.defaultProps = {
// };

export default ArrowLink;
