import React from 'react'
import Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
      minWidth: 275,
      minHeight: 660,
      padding: theme.spacing(3),
      marginTop: theme.spacing(4),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      overflowX: "hidden"
    },
    noPadding: {
      padding: 0
    },
    small: {
      margin: "auto",
      maxWidth: 500,
      minHeight: 'unset',
    }
  }));



const ConfiguratorCard = ({children, noPadding, small}) => {
    const classes = useStyles();

    return (
        <Card className={`${classes.root} ${noPadding && classes.noPadding} ${small && classes.small}`} elevation={1} xs={small ? 8 : 12} p={3}>
            {children}
        </Card>
    )
}

export default ConfiguratorCard
