import { atom, selector } from 'recoil';
import { Product, ConfiguratorEnums, getConfiguratorResult } from '../components/Configurator/ConfiguratorLogic'

export const defaultConfiguratorQuestionState = {
  typeOfProduct: "",
  typeOfProduce: "",
  amountOfThroughput: 5,
  positionInSupplyChain: "",
  name: "",
  email: "",
  company: "",
  phoneNumber: "",
}

export const defaultLeadRecordState = {
  id: null,
  wantsToEnquire: null,
  wantsFinancing: null,
}

  export const worldState = atom({
    key: 'worldState', // unique ID (with respect to other atoms/selectors)
    default: { // default value (aka initial value)
      world: 'recoil',
    }, 
  });

  export const configuratorQuestionState = atom({
    key: 'configuratorQuestionState',
    default: defaultConfiguratorQuestionState
  })

  export const leadRecordState = atom({
    key: 'leadRecordState',
    default: defaultLeadRecordState
  })

  // export const configuratorResultState = atom({
  //   key: 'configuratorResultState',
  //   default: {... new Product({})}
  // })

  export const configuratorResultState = selector({
    key: 'configuratorResultState', // unique ID (with respect to other atoms/selectors)
    get: ({get}) => {
      const questionState = get(configuratorQuestionState);
      const result = getConfiguratorResult(questionState);
      return result;
    },
  });