
import React from 'react'
import MuiImageSlider from 'mui-image-slider';
import { makeStyles, useTheme } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        height: "100%"
    },
    wrapper	: {
        height: "100%",
        maxHeight: "700px",
        minHeight: "300px",
        margin: "0 auto",
        width: "300",
    },
    img: {
        height: "100%",
        width: '100%',
        objectFit: "cover",
    }
  }))

const ImageSlider = ({images}) => {
    const theme = useTheme()
    const classes = useStyles();

    return (
        <MuiImageSlider arrowsColor={theme.palette.primary.main} alwaysShowArrows={true} classes={classes} images={images}/>
    )
}

export default ImageSlider;